<template>

  <!--<footer class="app-footer">
    <div class="possible-win pt-2 pb-2">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <span class="total-odds text-left">Stake (Kes)    15:00</span>
            <input type="hidden" name="amount" id="bet_amount"  value="15" v-model="stake">
          </div>

          <div class="col-6 text-right">
            <a href="live-games.html">
              <span class="possible-payout">Picked {{ betslip_count }} / 10 Matches</span>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div class="footer-menu">
      <div class="container">
        <div class="row">
          <div class="col-3 auto-select footer-menu-div text-center active-event">

              <span class="menu" @click="autoPick">
                  <svg class="bi bi-arrow-counterclockwise" fill="#fff" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M7 9h-7v-7h1v5.2c1.853-4.237 6.083-7.2 11-7.2 6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.286 0-11.45-4.844-11.959-11h1.004c.506 5.603 5.221 10 10.955 10 6.071 0 11-4.929 11-11s-4.929-11-11-11c-4.66 0-8.647 2.904-10.249 7h5.249v1z"/></svg>
                  <a class="d-block text-uppercase">Auto Select</a>

              </span>

          </div>

          <div class="col-2 jackpot-games footer-menu-div text-center">
            <a href="#">
              <span class="game-selected">
                  <span class="game-number-selected">{{ betslip_count }}</span>
              </span>
            </a>
          </div>

          <div class="col-7 placebet text-center text-uppercase">
            <a class="placebet-btn" v-bind:class="loading" @click="placeJPBet"> Place Bet</a>
          </div>
        </div>
      </div>
    </div>
  </footer>-->
  <footer class="app-footer">
    <div class="footer-menu" style="background-color: var(--primary-dark); margin-bottom: 10px">
      <div class="container">
        <div class="row pr-2 pl-2">
          <button class="btn btn-outline col" v-bind:class="isPicked('clear')" @click="clearJP">Clear</button>
          <button class="btn btn-outline col" v-bind:class="isPicked('share')" @click="shareBet">Share</button>
          <button class="btn btn-outline col" v-bind:class="isPicked('auto')" @click="autoPick">Auto Pick</button>
        </div>
      </div>
      <div  style="background-color: var(--primary-dark)">
        <div class="d-flex justify-content-between pr-2 pl-2">
          <div>Total Stake</div>
          <div>KES 15.00</div>
        </div>
        <div class="d-flex justify-content-between pr-2 pl-2">
          <div>Picked</div>
          <div>{{ betslip_count }}/10</div>
        </div>
      </div>
      <div class="pr-2 pl-2">
        <button class="btn btn-block btn-bet" @click="placeJPBet" style="color: #000;">Place Bet  KES 15.00</button>
      </div>
      <div class="text-center p-2">
        <p>By placing this jackpot bet, you agree to the Jackpot Terms and Conditions.</p>
      </div>
    </div>

    <span id="open-sharebet" class="hidden"></span>
    <!-- The Modal -->
    <div class="sharebet-modal text-center" id="sharebet-modalJ">

      <div class="sharebet-modal-content">

        <div class="row d-flex">
          <div class="col-12 share-bet-title justify-content-center">
            <strong>Share Your bet</strong>
          </div>
          <div class="col-12 share-bet-desc" @click="copyCode">
            Your booking code
            <div class="booking-code">
              <strong>{{ code }}</strong>
            </div>
            <strong>book#{{ code }}#stake to 40600</strong>
            <div style="font-size: 0.5em;">{{ copyBookingCode }}</div>

          </div>
        </div>

        <div class="row d-flex">

          <div class="col-12 btn-group-sm w-100">

            <a
                    data-action="share/whatsapp/share" target="_blank"
                    onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;"
                    class="btn share-bet-social whatsapp" style="font-size: 0.6em;">
              WhatsApp
            </a>


            <a
                    href="https://www.facebook.com/sharer/sharer.php?u=&t="
                    title="Share on Facebook"
                    onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                    target="_blank"
                    class="btn share-bet-social facebbok" style="font-size: 0.6em;">
              Facebook
            </a>

            <a
                    href="https://twitter.com/intent/tweet?"
                    target="_blank" title="Tweet"
                    onclick="window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                    class="btn share-bet-social twitter" style="font-size: 0.6em;">
              Twitter
            </a>

          </div>

          <div class="col-12">
            <div class="share-bet-link" id="share-bet-link">https://tapabet.co.ke/share/{{ code }}</div>
            <div class="hidden" id="share-bet-code">{{ code }}</div>
            <div class="hidden" id="share-bet-odds">{{ odds }}</div>
            <div class="hidden" id="share-bet-msg">{{ msg }}</div>
            <div class="share-bet-copy" @click="copyShareBetLink">{{ copyText }}</div>
          </div>

        </div>
      </div>
    </div>
  </footer>

</template>

<style scoped>
  .container{
    background-color: transparent;
  }
  .app-footer {
    position: inherit;
    bottom: 0;
    max-width: 768px;
    width: 100%;
    left: 0;
    right: 0;
    float: left;
    margin: 0 auto;
  }

  .btn-outline{
    border-color: #fff;
    color: #fff;
  }

  .possible-win {
    width: 100%;
    height: 30px;
    left: 0px;
    top: 0px;
    background: #fee21f;
    color: #202020;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    mix-blend-mode: normal;
  }

  .possible-win a span.possible-payout {
    color: #202020;
  }
  .footer-menu {
    background-color: #11a306;
  }
  .footer-menu-div.active-event {
    opacity: 1;
  }
  .footer-menu-div {
    width: 20%;
    float: left;
    color: #fff;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
  }
  .footer-menu a {
    color: #ffffff;
  }
  .jackpot-games .game-selected {
    background-color: #d32027;
    padding: 2px;
    padding-top: 2px;
    padding-top: 0;
    color: #fff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    left: 0;
    right: -5px;
    margin: 0 auto;
    bottom: 0;
    width: 55px;
  }
  .game-selected {
    background-color: #d32027;
    padding: 2px;
    padding-top: 7px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    width: 55px;
  }
  .jackpot-games .game-number-selected {
    font-weight: bold;
    margin-bottom: 0;
    padding: 14px 0;
    font-size: 25px;
  }
  .game-selected span {
    display: inline-block;
    width: 100%;
  }
  .game-number-selected {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .placebet {
    background-color: #3BB54B;
    color: #fff;
    font-size: 14px;
  }
  .placebet a {
    padding: 15px 50px;
    display: inline-block;
  }
  .footer-menu a {
    color: #ffffff;
  }

  .auto-select .bi-arrow-counterclockwise::before {
    font-size: 30px;
  }
  .bi-arrow-counterclockwise::before {
    content: "\f117";
  }
  [class*=" bi-"]::before, [class^="bi-"]::before {
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<script>

import axios from "@/services/api";

export default {
  name: "bottom-navigation",
  props: {

  },
  data: function () {
    return {
      stake: 15,
      loading: '',
      bestlip_visible: false,
      code: '',
      msg: '',
      share_odds: '',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: '',
      picked_button: '',
    }
  },
  methods: {
    clearJP: function (){

      this.picked_button = 'clear';
      this.clearJackpotBetSlip();

    },
    isPicked: function(share){

      return share === this.picked_button ? 'jp-selected-btn' : 'jp-btn';

    },
    loadBookingCode: function(){

      if(this.booking_code.trim().length > 4 ) {

        if(this.currentRouteName !== "share") {

          this.$router.push({name: 'share', params: {code: this.booking_code.trim()}});

        } else {

          this.EventBus.$emit('share:code', this.booking_code.trim());
        }

      } else {

        this.setError("invalid code","Please enter a valid booking code to proceed");

      }

    },
    shareBetMessage: function(){

      var code = this.code;
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * 200);
      this.msg =   "Cheki Hii bet nimeekelea kwa tapabet. If you stake 200 and you could win Ksh. "+toWin+". Betslip ndio Hii >> \n\n\n\n https://tapabet.co.ke/share/"+code;

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
    show: function () {

      this.jQuery('#betslip-modal').modal('show');
      //var modal = document.getElementById("betslip-modal");
      //modal.style.display = "block";
      this.bestlip_visible = true;
    },
    placeJPBet: function () {

      this.picked_button = 'bet';
      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.setValue("placeBet",3);
        this.$router.push({ name: 'login', params: { } });
        return;
      }

      var bet_amount = this.getValue("stake");
      var booking_code = this.getValue("booking_code");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      if (bet_amount < 1) {

        this.setError("Invalid Bet Amount", "Please enter bet amount greater of 1Ksh or more ");
        return;
      }

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select atleast one outcome to continue");
        return;
      }

      if (!this.accept_odds_changes) {

        this.setError("Odds Changed", "Accept Odds Changes to Proceed");
        return;

      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          odd_id: v.odd_id
        });

      });

      var data = {
        stake: bet_amount,
        bets: bets,
        code: booking_code,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
        source: this.isMobile() ? 2 : 1
      };


      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_JP_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var profile = res.data.message.profile;
            var message = res.data.message.message;
            vm.setProfile(profile);
            vm.clearJackpotBetSlip();
            vm.setSuccess("Success", message);
            vm.removeObject('booking_code');

          })
          .catch(err => {

            console.log(JSON.stringify(err,undefined,4));

            vm.loading = '';
            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message.message;
                var amount = err.response.data.message.amount;

                vm.EventBus.$emit('deposit:popup',{
                  amount: amount,
                  message:message,
                });

              }

              if(parseInt(err.response.status) === 422 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)
              }

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    oddStatus: function () {

      var picks = this.betslip.picks;

      if (picks === undefined || picks.length === 0) {

        return;

      }

      var bets = [];

      this.jQuery.each(picks, function (k, v) {

        bets.push(v.odd_id+":"+v.producer_id);

      });

      var data = {
        odd_id: bets.join(',')
      };

      if (bets.length === 0) {

        return;

      }

      var vm = this;

      var path = process.env.VUE_APP_URL_ODD_STATUS;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            var bets = res.data.message;

            vm.jQuery.each(bets,function(k,v){

              var oddID = v.id;
              var odds = v.odds;
              var previous_odds = v.previous_odds;
              var status = v.status;
              var active = v.active;
              var producer_status = v.producer_status;

              // get original odds
              // check odds changes
              vm.jQuery.each(picks,function(k1,v1){

                if(parseInt(v1.odd_id) === parseInt(oddID) ) {

                  var oddChangeText = "";
                  odds = vm.formatOdds(odds);

                  if(odds !== vm.formatOdds(v1.odd)) {

                    // odds changed
                    if(odds > v1.odd ) {

                      var direction = 1

                    } else {

                      direction = 0

                    }

                    var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
                    oddChangeText = directionTxt + ' from '+v1.odd+' to '+odds;

                  }

                  v1.status = status;
                  v1.active = active;
                  v1.odds = odds;
                  v1.odd = odds;
                  v1.previous_odds = previous_odds;
                  v1.producer_status = producer_status;
                  v1.odds_change_text = oddChangeText;
                  picks[k1] = v1;
                }
              })

            });

            var pk = [];

            vm.jQuery.each(picks,function(k1,v1){

                pk.push(v1);
            })

            vm.saveObject("jslip", pk);
            vm.autoRefreshJackpotUI(vm.$vnode.tag);

          })
          .catch(err => {

            if (err.response) {

              //vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              //vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },
    getOddStatus: function(odd) {

      if(odd.active === 0  || odd.producer_status === 0 ) {

        return '<div class="deactivated-odds">Outcome suspended</div>';

      }

      if(parseInt(odd.status) === 0 || parseInt(odd.status) === -1 ) {

        return '<div class="deactivated-odds">Outcome Deactivated</div>';

      }

      if(odd.odds_change_text && odd.odds_change_text.length > 0 ) {

        return '<div class="odds-change" style="">'+odd.odds_change_text+'</div>';

      }

    },
    initSharebetModal: function(){

      var modal = document.getElementById("sharebet-modalJ");

      // Get the button that opens the modal
      var btn = document.getElementById("open-sharebet");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function() {
        modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#sharebet-modalJ")) {

          modal.style.display = "none";
        }

      });

    },
    /*initBetslipModal: function(){

      var vm = this;

      // Get the modal
      var modal = document.getElementById("betslip-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("betslip-init");

      // Get the <span> element that closes the modal
      //var span = document.getElementById("faso-close");

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        vm.previous_odds = vm.betslip.odds;
        vm.oddStatus();
      }

      // When the user clicks on <span> (x), close the modal
      /!*span.onclick = function () {
        modal.style.display = "none";
      }*!/

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {

          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });


    },*/
    showBetslip: function() {

      document.getElementById("betslip-init").click();

    },
    hideBetslip: function() {

      document.getElementById("faso-close").click();

    },
    autoPick: function () {

      this.picked_button = 'auto';

      this.clearJackpotBetSlip();
      var max = 10;
      var r = 0;
      while (r < max ) {

        // generate random between 0 and 2
        var i = this.getRandomInt(3)
        var id = 'r'+r+'c'+i;
        //console.log('Wants to click '+id);
        this.jQuery("#"+id).click();
        r++
      }
    },

    shareBet: function () {

      this.picked_button = 'share';

      var p = this.getProfile();

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.setError("Invalid Bets", "Please Select at least one outcome to continue");
        return;
      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          market_id: v.market_id,
          match_id: v.match_id,
          outcome_id: v.outcome_id,
          specifier: v.specifier,
        });

      });

      var data = {
        profile_id: p.id,
        bets: bets,
        bet_type: 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SHARE;

      axios.post(path, JSON.stringify(data))
          .then(res => {

            vm.loading = '';
            vm.code = res.data.message;
            vm.share_odds = betslipData.odds;
            vm.shareBetMessage();
            console.log(vm.msg);
            vm.copyText = 'Copy';
            vm.copyBookingCode = 'Click to Copy'
            document.getElementById("open-sharebet").click();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },
    copyShareBetLink: function () {

      var link = "https://tapabet.co.ke/share/"+this.code;
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "book#"+this.code+"#stakeAmount";
      this.copyToClipboard(link);
      this.copyBookingCode = 'Booking Code Copied';

    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function() {

      return this.formatCurrency(this.profile.b1);

    },

    iconSize: function (){

      return 28;
    },
    profile: function () {

      return this.myProfile;

    },
    liveGames: function (){

      return this.$store.state.live_match_counter

    },
    homePageIcon: function () {

      if (this.page === 'home') {

        return this.getURL("/assets/images/home_icon_selected.svg");

      }

      return this.getURL("/assets/images/home_icon.svg");

    },
    homePageFontColor: function () {

      if (this.page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip_count: function () {

      if(!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },
    activeBets: function () {

      var p = this.getProfile();
      if(!p) {

        return 0;

      }

      return p.b

    },
    odds: function () {

      return this.betslip.odds
      //return this.$store.state.betslip.odds

    },
    payout: function () {

      return this.betslip.payout

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.jackpotbetslip
    },
    has_suspended_picks: function (){

      return this.$store.state.has_suspended_jackpot_picks

    },
    isLoggedIn: function (){

      var p = this.getProfile();
      if(!p) {
        return false;
      }

      return true;
    },

  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  watch: {

    stake: function (newValue) {

      // update stake
      this.setValue("stake", newValue);

      // recalculate winnings
      this.autoRefreshJackpotUI(this.$vnode.tag);

    }
  },
  components: {
  },
  mounted: function () {

    // reset stake
    this.setValue("stake",15);
    this.initSharebetModal();
    this.previous_odds = this.betslip.odds;

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;

    this.EventBus.$on('event:jackpotbetslip:show',function(){

      vm.showBetslip();

    });

    this.EventBus.$on('event:jackpotbetslip:hide',function(){

      vm.hideBetslip();

    });

    this.EventBus.$on('event:jackpotbetslip:placeBet',function(){

      vm.placeBet(true);

    });

    this.EventBus.$on('profile:balance',function(payload){

      vm.myProfile = payload;

    });

    this.EventBus.$on('jackpotodds:changed',function(payload){

      vm.odds_changed = true;
      vm.updateJackpotOdd(payload.id,payload.odds,payload.previous_odds,payload.active,payload.status);

    });

  }
}
</script>
