<template>

  <div class="wrapper">
    <Menu></Menu>

    <div class="body mt-1">
      <div class="jackpot-display pt-3 pb-2 mb-4 text-center">
        <div class="container">
          <div class="row" style="line-height: 0;">
            <p class="w-100 text-uppercase">Daily Jackpot</p>

            <h1 class="w-100 jackpot-value">Win KES 250,000</h1>

            <div class="countdown">
              <span class="expiry">Expires in</span>&nbsp;&nbsp;
              <span id="betingtime" v-show="current_page == 'jackpot'"></span>
            </div>
          </div>
        </div>
      </div>

      <!--Tabs-->
      <div id="tabs">
        <div class="l-tabs">
          <a @click="key = 1" :class="{ active: isActive}">Matches</a>
          <a @click="key = 2" :class="{ active: isActive}">Results</a>
          <a @click="key = 3" :class="{ active: isActive}">How to play</a>
        </div>
      </div>
      <div class="jp-fixture" v-show="key == 1">
        <JackpotGames
            v-for="(m,index) in matches"
            v-bind:key="getLeagueKey(index)"
            v-bind:current_row="index"
            v-bind:fixture="m"></JackpotGames>

        <BottomNavigation v-if="matches.length != 0"></BottomNavigation>
        </div>
        <div v-if="matches.length == 0">
          <p class="display-4 pt-5 d-flex w-100 text-center">Events for the Daily Jackpot are not available. Please check back later</p>
        </div>

    </div>

    <!--RESULTS-->
    <div class="l-results" v-show="key == 2">
      <div class="container pl-0 pr-0">
        <div v-show="!view_more">
          <div class="l-prev" v-for="(r,i) in results" :key="i">
            <div class="row" @click="viewResult(r)">
              <div class="col-12 d-flex pl-2 pr-2 justify-content-between">
                <span>{{r.name}}</span>
                <span>{{formatDate(r.end)}}</span>
              </div>

              <div class="col-12 d-flex pl-2 pr-2 justify-content-between">
            <span>{{r.prize}}</span>
            <span><svg height="18" viewBox="0 0 6 8" xmlns="http://www.w3.org/2000/svg" class="jackpot__event__arrow"><path d="M4.95312 4.26562C5.03125 4.20312 5.07812 4.10938 5.07812 4C5.07812 3.90625 5.03125 3.8125 4.95312 3.73438L1.92188 0.703125C1.84375 0.640625 1.75 0.59375 1.65625 0.59375C1.54688 0.59375 1.45312 0.640625 1.39062 0.703125L1.04688 1.04688C0.96875 1.125 0.921875 1.21875 0.921875 1.3125C0.921875 1.42188 0.953125 1.51562 1.03125 1.57812L3.45312 4L1.03125 6.42188C0.953125 6.5 0.921875 6.59375 0.921875 6.6875C0.921875 6.79688 0.96875 6.89062 1.04688 6.95312L1.39062 7.40600C1.45312 7.375 1.54688 7.40625 1.65625 7.40625C1.75 7.40625 1.84375 7.375 1.92188 7.40600L4.95312 4.26562Z"></path></svg></span>
          </div>
            </div>
        </div>
        </div>

        <div v-if="view_more" class="container">
          <div class="row">
            <div class="col-4 text-left"><h6>Matches</h6></div>
            <div class="col-4 text-center"><h6>Scores</h6></div>
            <div class="col-4 text-center"><h6>Result</h6></div>
          </div>
          <div class="row" v-for="(p,index) in prev.match_results" :key="index">
              <div class="col-12" style="font-size: 0.6em">
                <span><!--{{ p.country_code }} /--> <span class="text-capitalize">{{ p.competition }}</span></span><!--<br><span>{{formatDate(p.date)}}</span>-->
              </div>
            <div class="col-4 ">
              <span style="font-size: var(--font-medium); text-transform: capitalize"  class="home-away bold">
                {{ shortenName(getHomeCompetitorName(p.name),30) }}
              </span>
              <span style="font-size: var(--font-medium); text-transform: capitalize"  class="home-away bold">
                {{ shortenName(getAwayCompetitorName(p.name),30) }}
              </span>
            </div>

            <div class="col-4 text-center">
              <span>{{p.home_score}}</span><br>
              <span>{{p.away_score}}</span>
            </div>
            <div class="col-4 text-center">
              <button class="btn btn-sm" v-if="p.home_score > p.away_score">1</button>
              <button class="btn btn-sm" v-else-if="p.home_score == p.away_score">X</button>
              <button class="btn btn-sm" v-else-if="p.home_score < p.away_score">2</button>
            </div>
            <div class="col-12" style="font-size: 0.6em">
              <span>{{formatDate(p.date)}}</span>
            </div>
            <div class="col-12" style="height: .1em; background-color: #e9e9e9; "></div>
          </div>

        </div>
      </div>
    </div>

    <div class="l-howto" v-show="key == 3">
      <h4>INTRODUCTION</h4>
      <p>
        The tapabet Daily Jackpot is a pool betting product promoted and operated by tapabet on pre-selected football matches. The tapabet 10 team Daily Jackpot Competition consists of predicting the results of 10 matches, which are selected by tapabet every week.
      </p>
      <p>
        To take part and have a chance to win the tapabet jackpot, you must get registered on tapabet .com and have at least KES 10 in your tapabet account. If you correctly predict all 10 match results, you win the Daily Jackpot prize of Kshs 250,000/=.
      </p>

      <h4>THE COMPETITION</h4>
      <p>
        The tapabet 10 team Daily Jackpot (the “Competition(s)”) is offered by tapabet.co.ke. Entrants are required to submit their predictions of the result of 10 pre-selected football matches (the "Matches") at tapabet.co.ke/jackpot (the “Competition Entry Page”) before the first scheduled match of each competition kicks off (the “Competition Closing Time”) or via SMS. To win a share of the jackpot, participants must correctly predict the final result of all Matches.
      </p>

      <h4>GENERAL tapabet DAILY JACKPOT TERMS AND CONDITIONS</h4>
      <p>
        Matches will be settled on the official result after 90 minutes of play, including any ‘injury time'. Extra time and penalty shoot-outs shall not be included.
      </p>
      <p>
        This Competitions are open to persons aged 18 years or over, resident in Kenya. Proof of age and identity may be required.This Competitions are open to persons aged 18 years or over, resident in Kenya. Proof of age and identity may be required.
      </p>
      <p>
        The tapabet Daily Jackpot prize money is KES 250,000/=
      </p>
      <p>
        Players will be deemed to have accepted these terms and conditions and agreed to be bound by them when entering this Competition.
      </p>

      <h4>DAILY JACKPOT BET</h4>
      <p>
        The Daily Jackpot amount is subject to change on a daily basis.
      </p>
      <p>
        The Daily Jackpot is based on ten (10) pre-selected football games.
      </p>
      <p>
        The Daily Jackpot amount, consolation prizes and/or any bonuses thereof will be divided equally amongst all the Daily Jackpot winners.
      </p>
      <p>
        To play Make your selections – On the tapabet Daily Jackpot competition entry page (tapabet.co.ke/jackpot), make your predictions for each one of the listed matches (Home win, Draw, Away win). The stake amount of each combination is KES 15. You can NOT make more than 1 prediction for one match.
      </p>
      <p>
        The Daily Jackpot bet can be placed by submitting predictions of the result of 10 pre-selected football matches (the "Matches") at tapabet .com/jackpot or through;
      </p>

        <ol>
          <li>a manual option where you select each prediction i.e. SMS “DJP” followed by the 10 predictions to 40600. E.g. DJP#1X21X21X21 you will receive a confirmation SMS for your bet with the possible Daily Jackpot win, once the bet is placed. OR</li>
          <li>an ‘Auto Bet’ method as follows SMS “DJP#?” to 40600. The tapabet System then randomly selects the 10 predictions for you. You will receive a confirmation SMS for your bet with the possible Daily Jackpot win, once the bet is placed. </li>
          <li>By choosing the “Auto Bet” option, a random selection of one prediction per match will be automatically selected with a fixed stake of KES 15. </li>
        </ol>

      <h4>DAILY JACKPOT BONUS</h4>
      <ol>
        <li>Daily Jackpot Players are eligible to additional prizes, such as the Jackpot bonus.</li>
        <li>The Daily Jackpot bonus amount shall be determined by the Company at its discretion.</li>
        <li>Where two or more games are cancelled, interrupted, abandoned, suspended or postponed, the Company may at its discretion but with strict adherence to BCLB guidelines, cancel the Daily Jackpot and refund the stake(s) placed within 72 hours of the cancellation.</li>
      </ol>

      <h4>ADDENDUM</h4>
      <p>Check and place bets – Make sure to check all of the selections before you click on the "Place Bet" button. Once submitted, the bets cannot be cancelled, amended or refunded. </p>
      <p>Where a Daily Jackpot game is cancelled, an official public draw shall be carried out, with strict compliance to BCLB requirements after seventy-two (72) hours from the time of cancellation, to determine the result of the missing game result. Where two or more games are cancelled, interrupted, abandoned, suspended or postponed, tapabet may at its discretion, cancel the Daily Jackpot and refund the stake placed within 48 hours of the cancellation.</p>
      <p>Winners shall be required to avail themselves in tapabet offices with proof of identity before any payment is made. tapabet reserves the right to verify, with the relevant authorities, any identification document presented, before making any payment.</p>
      <p>The period for claiming the prize is fourteen (14) days failure to which tapabet may deem the prize forfeited, unless the period is extended, at the sole discretion of tapabet.</p>
      <p>tapabet reserves the right to withhold 90% of any prize share until the prize presentation ceremony.</p>
      <p>tapabet reserves the right to pay the whole amount of any prize share to a winner by cheque or bank transfer.</p>
      <p>tapabet ' decision is final and legally binding on all entrants in relation to all aspects of the Competition including (without limitation) allocation of the prizes and no correspondence will be entered into.</p>
      <p>tapabet, with the guidance of BCLB, reserves the right to amend the terms and conditions of this Competition at any time, for any reason and without notice.</p>
      <p>Employees of tapabet, Dreamhub Technologies, their relatives, agents or agents' relatives are not eligible to win prizes.</p>
      <p>tapabet General Terms and Conditions apply.</p>
    </div>

    <BottomFooter></BottomFooter>

  </div>

</template>

<style scoped>
  .l-tabs {
    display: table;
    width: 100%;
    overflow: auto;
    background-color: #11a306;
    color: #fff;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }
  .l-tabs a {
    display: table-cell;
    width: 33%;
    padding: 0.85em 0;
    text-align: center;
    font-size: 0.9em;
    color: #fff;
  }
  .l-tabs a.active {
    background: #fff;
    color: #222;
    font-weight: 700;
  }
  .l-tabs a.active.live {
    color: #f00;
  }

  .btn.btn-sm{
    background-color: #e5e5e5;
    color: #000;
    font-weight: bold;
  }
  .l-howto {
    padding: 0.75em;
    background: #fff;
    margin-bottom: 4em;
  }
  /*.jp-fixture{
    margin-bottom: 6em;
  }*/
  .jackpot-display {
    width: 100%;
    float: left;
  }

  h1.jackpot-value {
    font-size: 36px;
    background-image: linear-gradient(45deg, #123115, #3bb54b);
    background: -webkit-linear-gradient(#123115, #3bb54b);
    -webkit-background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: inherit;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0px 2px 5px rgba(32, 32, 32, 0.3);
  }

  .countdown {
    width: 100%;
    float: left;
  }

  #betingtime {
    text-transform: uppercase;
    font-weight: 600;
  }

  .l-prev{
    padding: 0.8125em 1.25em 0;
    margin-top: 0;
    border: 1px solid #eee;
    border-left-color: rgb(238, 238, 238);
    border-radius: .25rem;
  }

  .l-results>.container{
    max-width: 768px;
  }
</style>

<script>
  import axios from "@/services/api";
  import JackpotGames from "./JackpotGames";
  import BottomNavigation from "./BottomNavigation";
  import Menu from "../Menu";
  import BottomFooter from "../BottomFooter";

  export default {
    name: 'Jackpot',
    components: {
      JackpotGames,
      BottomNavigation,
      Menu,
      BottomFooter
    },
    data: function () {
      return {
        end: '',
        countDown: '',
        matches: [],
        details: [],
        results: [],
        prev: [],
        sub_page: '',
        key: '1',
        isActive: false,
        activeClass: 'active',
        view_more: false,
        loading: '',
        btn: ''
      }
    },
    methods: {
      makeActive: function(item) {
        // When a model is changed, the view will be automatically updated.
        this.active = item;
      },
      getLeagueKey: function(index){

        return Math.random().toString(10).replace('0.', 'competition-id-'+index + '-');

      },

      getOutcomeKey: function(index){

        return Math.random().toString(10).replace('0.', 'outcome-'+index + '-');

      },

      getJackpot: function () {

        var vm = this;
        var path = process.env.VUE_APP_URL_JP;

        axios.post(path, JSON.stringify({}))
        .then(res => {
          var games = res.data.message.data;
          vm.matches = games;
        })

      },

      getColor: function(home, away){
        if (home > away){
          return "btn btn-success";
        }else if(home < away){
          return "btn btn-danger"
        }else {
          return "btn"
        }
      },
      getJackpotResult: function () {

        var vm = this;
        var path = process.env.VUE_APP_URL_JP_RESULT.replace("{count}", 5);

        axios.post(path, JSON.stringify({}))
        .then(res => {
          var games = res.data.message;
          vm.results = games;
        })

      },

      getJackpotDetails: function () {

        var path = process.env.VUE_APP_URL_JACKPOT;

        axios.post(path, JSON.stringify({}))
        .then(res => {
          var games = res.data.message[0].end;
          this.jpCountDown(games);

        })
      },

      getMonth(i){
        var month = new Array();
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";

        return month[i]
      },

      addZero: function (i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      },

      jpCountDown: function(game_time){
        let end_date = new Date(game_time);
        let month = end_date.getMonth();
        let day = end_date.getDate();
        let year = end_date.getFullYear();
        let hour = end_date.getHours();
        let min = end_date.getMinutes();
        let second = end_date.getSeconds();

        month = this.getMonth(month);
        second = this.addZero(second);

        //console.log('Date-->', month+' '+day+', '+year+' '+hour+':'+min+':'+second);

        let fullDate =  month+' '+day+', '+year+' '+hour+':'+min+':'+second;
        // Set the date we're counting down to
        var countDownDate = new Date(fullDate).getTime();

        // Update the count down every 1 second
        var x = setInterval(function () {
          // Get today's date and time
          var now = new Date().getTime();

          // Find the distance between now and the count down date
          var distance = countDownDate - now;

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Output the result in an element with id="demo"
          document.getElementById("betingtime").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
          //this.countDown = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
          // If the count down is over, write some text
          if (distance < 0) {
            clearInterval(x);
            document.getElementById("betingtime").innerHTML = "EXPIRED";
            //this.countDown = "EXPIRED";
          }
        }, 1000);
      },

      formatDate: function (date) {

        if (date === false || date === undefined || date === "" || date === null) {

          return ""
        }

        var res = date.replace(" ", "T");
        var d = new Date(res);
        var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        var mon = months[d.getMonth()];
        var dt = d.getDate();
        var day = dt > 9 ? dt : '0' + dt;
        var hr = d.getHours();
        var hour = hr > 9 ? hr : '0' + hr;
        var min = d.getMinutes();
        var minutes = min > 9 ? min : '0' + min;
        var formated = day + '/' + mon + ', ' + hour + ':' + minutes;
        return formated;
      },

      viewResult: function(prev) {

        this.view_more = true;

        this.prev = prev;

      },
      getHomeCompetitorName: function (matchName) {

        if (matchName === undefined) {

          return "";
        }

        return matchName.split('vs.')[0];
      },
      getAwayCompetitorName: function (matchName) {

        if (matchName === undefined) {

          return "";
        }
        return matchName.split('vs.')[1];
      },
    },
    mounted() {
      this.$store.dispatch("setCurrentPage", "jackpot");
      this.getJackpot();
      //this.jpCountDown();
      this.getJackpotDetails();
      this.getJackpotResult();
    },
    computed: {
      current_sub_page: function () {

        return this.$store.state.current_sub_page;
      },
      current_page: function () {

        return this.$store.state.current_page;
      }
    }
  }
</script>


